import React from "react";
import { Layout, Menu } from "antd";
import Icon, { ProfileOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as roles from "./../services/constants/roles";

const { Sider } = Layout;
const { SubMenu } = Menu;

function MySider(props) {
  return (
    <Sider
      style={{ background: "#97be11", overflow: "hidden", minWidth: "250px" }}
      theme="light"
      collapsible
      trigger={null}
      collapsed={false}
      collapsedWidth={220}
      className="remove-onPrint"

      // onMouseEnter={(e) => {
      // 	setCollapsed(false);
      // }}
      // onMouseLeave={(e) => {
      // 	setCollapsed(true);
      // }}
    >
      <div id="sider">
        <div className="logo">
          <img src="/vectors/Logo.png" alt="" />
        </div>

        <Menu style={{ background: "inherit" }} selectedKeys={[props.selectedMenu]} mode="inline">
          <Menu.Item key="1" icon={<Icon component={() => <img src="/vectors/Dashboard.svg" alt="icon" />} />}>
            <Link to="/">Dashboard</Link>
          </Menu.Item>

          <Menu.Item key="2" icon={<Icon component={() => <img src="/vectors/Orders.svg" alt="icon" />} />}>
            <Link to="/orders">Orders</Link>
          </Menu.Item>

          <Menu.Item key="3" id="3" icon={<Icon component={() => <img src="/vectors/Order-Guides.svg" alt="icon" />} />}>
            <Link to={"/order-guides"}>Order Guides</Link>
          </Menu.Item>
          <Menu.Item key="invoices" id="invoices" icon={<Icon component={() => <img src="/vectors/Invoices.svg" alt="icon" />} />}>
            <Link to={"/invoices"}>Invoices</Link>
          </Menu.Item>
          {roles.ADMIN_ROLE_ID !== props.roleId ? (
            <Menu.Item key="4" icon={<Icon component={() => <img src="/vectors/Products.svg" alt="icon" />} />}>
              <Link to="/products">Products</Link>
            </Menu.Item>
          ) : (
            <SubMenu key="4" icon={<Icon component={() => <img src="/vectors/Products.svg" alt="icon" />} />} title={"Products"}>
              <Menu.Item key="40">
                <Link to="/products">All Products</Link>
              </Menu.Item>

              <Menu.Item key="41">
                <Link to="/products/types">Product Types</Link>
              </Menu.Item>

              <Menu.Item key="42">
                <Link to="/products/vendors">Vendors</Link>
              </Menu.Item>

              <Menu.Item key="43">
                <Link to="/products/order-guide-types">Order Guide Types</Link>
              </Menu.Item>
            </SubMenu>
          )}
          <SubMenu key="Reports" icon={<Icon component={() => <img src="/vectors/Reports.svg" alt="icon" />} />} title={"Reports"}>
              <Menu.Item key="order-products">
                <Link to="/order-products">Order Products</Link>
              </Menu.Item>
          </SubMenu>
          {roles.SCHOOL_ROLE_ID === props.roleId ? (
            ""
          ) : (
            <Menu.Item key="5" icon={<Icon component={() => <img src="/vectors/Users.svg" alt="icon" />} />}>
              <Link to="/users">{roles.ADMIN_ROLE_ID === props.roleId ? "Users" : "Schools"}</Link>
            </Menu.Item>
          )}
          {roles.ADMIN_ROLE_ID === props.roleId && (
            <Menu.Item key="7" icon={<Icon component={() => <ProfileOutlined style={{ fontSize: 21, color: "#4b6335" }} />} />}>
              <Link to="/news">News</Link>
            </Menu.Item>
          )}
        </Menu>

        <Menu style={{ background: "inherit" }} defaultSelectedKeys={["1"]} mode="inline">
          <Menu.Item
            onClick={(e) => {
              localStorage.removeItem("jwt");
              window.location.href = "/";
            }}
            style={{ position: "absolute", bottom: "10px" }}
            key="1"
            icon={<Icon component={() => <img src="/vectors/Logout.svg" alt="icon" />} />}
          >
            <div>Log out</div>
          </Menu.Item>
        </Menu>
      </div>
    </Sider>
  );
}

function mapStateToProps(state) {
  return {
    roleId: state.me.roleId,
    selectedMenu: state.ui.selectedMenu,
  };
}

export default connect(mapStateToProps, null)(MySider);
