import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { Layout, Spin } from "antd";
import axios from "axios";
import { connect } from "react-redux";

import ForgotPassword from "./pages/notAuthenticated/ForgotPassword";
import SignIn from "./pages/notAuthenticated/SignIn";
import SignUp from "./pages/notAuthenticated/SignUp";
import ResetPassword from "./pages/notAuthenticated/ResetPassword";
import Dashboard from "./pages/dashboard";
import Sider from "./components/Sider";
import OrdersPage from "./pages/orders";
import OrderGuidPage from "./pages/orderGuides";
import ProductsPage from "./pages/products";
import UsersPage from "./pages/users";
import VendorsPage from "./pages/products/vendorsPage";
import TypesPage from "./pages/products/typesPage";
import ProductInfoPage from "./pages/products/ProductInfoPage";
import NotFoundPage from "./pages/messages/NotFoundPage";
import UserInfoPage from "./pages/users/UserInfoPage";
import { useDispatch } from "react-redux";
import { getData } from "./store/reducers/dataSlice";
import { getData as loadUser } from "./store/reducers/meSlice";
import VendorsInfoPage from "./pages/products/VendorsInfoPage";
import TypeInfoPage from "./pages/products/TypeInfoPage";
import OrderInfoPage from "./pages/orders/OrderInfoPage";
import InvoicesPage from "./pages/invoices/index";
import OrderGuidInfoPage from "./pages/orderGuides/OrderGuidInfoPage";
import Profile from "./pages/Profile";
import CreateOrderPage from "./pages/orders/CreateOrderPage";
import CreateOrderGuidPage from "./pages/orderGuides/CreateOrderGuidPage";
import SchoolRegisterationCompletions from "./pages/notAuthenticated/SchoolRegisterationComplation";
import { ADMIN_ROLE_ID, SCHOOL_ROLE_ID } from "./services/constants/roles";
import TermsAndConditions from "./pages/notAuthenticated/TermsAndConditionsPage";
import OrderGuideTypePage from "./pages/products/OrderGuideType";
import OrderGuideTypeInfoPage from "./pages/products/OrderGuideTypeInfo";
import ConfirmedEmail from "./pages/notAuthenticated/ConfirmedEmail";
import moment from "moment";
import News from "./pages/news";
import OrderProducts from "./pages/reports/orderProducts";
import CreateInvoices from "./pages/invoices/CreateInvoices";

function App(props) {
  const [profileText, setProfileText] = useState(`${props.name} - ${props.roleName}`);
  const [auth, setAuth] = useState(false);

  const { Content } = Layout;

  const dispatch = useDispatch();

  axios.defaults.baseURL = process.env.REACT_APP_API_ROOT || "https://kb.digilabs.ca";

  useEffect(() => {
    const token = localStorage.getItem("jwt");
    const state = localStorage.getItem("state");
    const user = JSON.parse(state)?.user;
    user && user.role?.id !== 4 && setProfileText(`${user.FirstName}(${user.DistrictOrSchoolName}) - ${user.role?.name}`);
    user && user.role?.id === 4 && setProfileText(`${user.FirstName} - ${user.role?.name}`);
    setAuth(token ? true : false);
    if (token) {
      axios.interceptors.request.use((config) => {
        config.headers.authorization = `Bearer ${token}`;
        return config;
      });
    }
  }, []);

  useEffect(() => {
    if (auth) dispatch(loadUser());
  }, [auth, dispatch]);

  useEffect(() => {
    dispatch(getData());
  }, [dispatch, props.select, props.query, props.baseUrl]);


  return (
    <div className="App" id="app">
      {!auth ? (
        <>
           <BrowserRouter>
            <Routes>
              <Route path="*" element={<SignIn />} />
              {/* <Route index element={<SignIn />} /> */}
              <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
              <Route path="/register" element={<SignUp />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/change-password" element={<ResetPassword />} />
              <Route path="/confirmed-email" element={<ConfirmedEmail />} />
              <Route path="/complete-registration" element={<SchoolRegisterationCompletions />} />
            </Routes>
          </BrowserRouter>
          <div style={{ position: "absolute", bottom: 5, right: 5 }}>
          <span style={{ color: "#f0f2f5" }}>{process.env.REACT_APP_VERSION || `v${moment().format("y.M.D-H.m")}`}</span>
        </div>
        </>
      ) : (
        <div style={{ height: "100vh" }}>
          <Spin spinning={props.loading} tip="We are getting your data please wait..." style={{ minHeight: "100vh" }}>
            <Layout style={{ minHeight: "100vh" }}>
              <BrowserRouter>
                <Sider />

                <Layout className="site-layout" style={{ position: "relative" }}>
                  <Content className="Routes" style={{ margin: "0 27px" }}>
                    <Routes>
                      <Route path="*" element={<NotFoundPage />} />

                      <Route path="profile" element={<Profile />} />

                      <Route path="orders">
                        <Route path=":id" element={<OrderInfoPage />} />
                        <Route path="new" element={<CreateOrderPage functionality="create" />} />
                          <Route path="edit/:id" element={<CreateOrderPage functionality="edit" />} />
                        <Route index element={<OrdersPage />} />
                      </Route>

                      <Route path="order-products" element={<OrderProducts/>}/>
                      <Route path="invoices">
                        <Route path=":id" element={<CreateInvoices functionality="update"/>} />
                        <Route path="new" element={<CreateInvoices functionality="create"/>} />
                        <Route index element={<InvoicesPage />} />
                      </Route>
                      <Route path="order-guides">
                        <Route path=":id" element={<OrderGuidInfoPage />} />
                        {props.roleId !== SCHOOL_ROLE_ID && (
                          <Route path="new" element={<CreateOrderGuidPage functionality="create" />} />
                        )}
                        {props.roleId !== SCHOOL_ROLE_ID && (
                          <Route path="edit" element={<CreateOrderGuidPage functionality="edit" />} />
                        )}
                        <Route index element={<OrderGuidPage />} />
                      </Route>

                      <Route path="products">
                        <Route path="product">
                          {props.roleId === ADMIN_ROLE_ID && (
                            <Route path="new" element={<ProductInfoPage functionality="create" />} />
                          )}
                          <Route path=":id" element={<ProductInfoPage functionality="update" />} />
                        </Route>

                        {props.roleId === ADMIN_ROLE_ID && (
                          <>
                            <Route path="vendors">
                              <Route path="new" element={<VendorsInfoPage functionality="create" />} />
                              <Route path=":id" element={<VendorsInfoPage functionality="update" />} />
                              <Route index element={<VendorsPage />} />
                            </Route>
                            <Route path="types">
                              <Route path="new" element={<TypeInfoPage functionality="create" />} />
                              <Route path=":id" element={<TypeInfoPage functionality="update" />} />
                              <Route index element={<TypesPage />} />
                            </Route>

                            <Route path="order-guide-types">
                              <Route path="new" element={<OrderGuideTypeInfoPage functionality="create" />} />
                              <Route path=":id" element={<OrderGuideTypeInfoPage functionality="update" />} />
                              <Route index element={<OrderGuideTypePage />} />
                            </Route>
                          </>
                        )}

                        <Route index element={<ProductsPage />} />
                      </Route>

                      <Route path="users">
                        <Route path=":id" element={<UserInfoPage functionality="update" />} />
                        {props.roleId !== SCHOOL_ROLE_ID && (
                          <Route path="new" element={<UserInfoPage functionality="create" />} />
                        )}
                        <Route path="districts" element={<UsersPage forDistrict={true} />} />
                        <Route path="schools" element={<UsersPage forSchool={true} />} />
                        <Route index element={<UsersPage />} />
                      </Route>
                      {props.roleId === ADMIN_ROLE_ID && (
                        <Route path="news">
                          <Route index element={<News />} />
                        </Route>
                      )}
                      <Route index element={<Dashboard />} />
                    </Routes>
                  </Content>

                  <Link to="/profile" style={{ color: "white" }}>
                    <div
                      className="avatar flex remove-onPrint"
                      style={{
                        background: "#97BE11",
                        cursor: "pointer",
                      }}
                    >
                      {`${profileText}`}
                    </div>
                  </Link>
                </Layout>
              </BrowserRouter>
            </Layout>
          </Spin>
        </div>
      )}
    
    </div>
  );
}

function mapStateToProps(state) {
  return {
    query: state.data.query,
    select: state.data.select,
    url: state.data.baseUrl,

    roleId: state.me.roleId,
    name: state.me.data.FirstName,
    loading: state.me.isLoading,
    roleName: state.me.data.role ? state.me.data.role.name : "",
  };
}

export default connect(mapStateToProps, null)(App);
